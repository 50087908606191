<template>
  <TTView>
    <VRow>
      <VCol>
        <SkillForm
          :entity="skill"
          @update:name="skill.name = $event"
          @update:description="skill.description = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_SKILL } from '../../components/competency-matrix/skills/common';
import SkillForm from '../../components/competency-matrix/skills/SkillForm.vue';

export default {
  name: 'SkillCreate',

  components: {
    SkillForm,
  },

  data() {
    return {
      loading: false,
      skill: { ...DEFAULT_SKILL },
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        const { skill } = this;
        await this.$di.api.CompetencyMatrix.SkillsCreate({ skill });
        this.resetForm();
        this.$di.notify.snackSuccess('Навык успешно создан');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },

    resetForm() {
      this.skill = { ...DEFAULT_SKILL };
    },
  },
};
</script>
