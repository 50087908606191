<template>
  <VCard>
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Имя"
                @input="$emit('update:name', $event)"
                @keydown.enter="handleSubmit"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.description"
                label="Описание"
                @input="$emit('update:description', $event)"
              />
            </VCol>
          </VRow>

          <VRow
            v-if="entity.descriptionHtml"
            align="center"
          >
            <VCol>
              <VTextarea
                disabled
                :value="entity.descriptionHtml"
                label="Описание HTML"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { DEFAULT_SKILL } from './common';

export default {
  name: 'SkillForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_SKILL }),
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание навыка';

      if (this.entity.id !== null) {
        title = 'Редактирование навыка';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
